
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["element"];
    static values = {
        dependents: String
    }

    connect() {
        // this.outputTarget.textContent = 'Hello, Stimulus!'
    }

    toggle() {
        this.toggleItems();
        this.toggleQuestions();
    }

    toggleItems(){
        const dependents = event.currentTarget.getAttribute("data-logic-dependents");
        if (dependents) {
            const dep = dependents.split(" ");
            dep.forEach((id) => {
                const el = document.getElementById(id);
                if (el.classList.contains("hidden")) {
                    console.log("show", id);
                    el.classList.remove("hidden-completely");
                    el.classList.remove("hidden");
                } else {
                    console.log("hide", id);
                    el.classList.add("hidden");
                    el.classList.add('hidden-completely');
                }
            });
        }
    }

    toggleQuestions(){
        const dependents = event.currentTarget.getAttribute("data-logic-dependent-questions");
        console.log('deps', dependents.length);

        if (dependents) {
            const dep = dependents.split(" ");
            dep.forEach((id) => {
                const el = document.getElementById(id);
                console.log('el', el);

                if (el.classList.contains("hidden")) {
                    console.log("show", id);
                    el.classList.remove("hidden-completely");
                    el.classList.remove("hidden");
                } else {
                    console.log("hide", id);
                    el.classList.add("hidden");
                    el.classList.add('hidden-completely');
                }
            });
        }
    }
}
